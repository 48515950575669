import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const Wrapper = styled.div`
  width: 275px;
  height: 350px;
  margin: 15px;
  border-radius: 20px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 1);
  background-repeat: no-repeat;
  background-image: ${props =>
    props.src
      ? `url(
          ${props.src}
        )`
      : ""};
  background-position: center;
  background-size: cover;
  transform: scale(1);
  transition-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 275px;

  &:hover {
    transform: scale(1.05);
    transition-duration: 0.5s;
  }

  a {
    text-decoration: none;
  }
`

const Title = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
`

const Date = styled.div`
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  font-style: italic;
`

const Excerpt = styled.div`
  font-size: 14px;
  margin: 20px;
  color: white;
`

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 35px;
`

const ReadMore = styled.div`
  border: 1px solid white;
  color: white;
  font-size: 12px;
  padding: 7px;
`

const Post = ({
  title,
  date,
  link,
  imageSrc = "https://images.unsplash.com/photo-1513569771920-c9e1d31714af?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGJsYWNrfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60",
  excerpt,
  primaryColor = null,
  secondaryColor = null,
  tertiaryColor = null,
}) => {
  return (
    <Wrapper src={imageSrc} href={link}>
      <Link to={link} itemProp="url">
        <Title>{title}</Title>
        <Date>{date}</Date>
        <Excerpt>{excerpt}</Excerpt>
        <Container>
          <ReadMore>Read More</ReadMore>
        </Container>
        {/* <img src={imageSrc}></img> */}
      </Link>
    </Wrapper>
  )
}

export default Post
